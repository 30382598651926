.react-calendar {
    width: 30rem;
    max-width: 90%;
    background: white;
    box-shadow: #c0c0c0 0.05rem 0.05rem;
    border: 1px solid #d4d4d4;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
}

.react-calendar--doubleView {
    width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer>* {
    width: 50%;
    margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
}

.react-calendar button:enabled:hover {
    cursor: pointer;
}

.react-calendar__navigation {
    display: flex;
    height: 3rem;
    margin-bottom: 1em;
}

.react-calendar__navigation button {
    min-width: 3rem;
    background: none;
}


/* .react-calendar__navigation button:disabled {
    background-color: #f0f0f0;
} */

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: #e6e6e6;
}

.react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font: inherit;
    font-size: 0.75em;
    font-weight: bold;
}

.react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
}


/* .react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font: inherit;
    font-size: 0.75em;
    font-weight: bold;
} */

.react-calendar__month-view__days__day--weekend {
    color: #3457D5;
    background-color: #afaf19;
}

.react-calendar__month-view__days__day--neighboringMonth,
.react-calendar__decade-view__years__year--neighboringDecade,
.react-calendar__century-view__decades__decade--neighboringCentury {
    color: #d5d5d5;
}


/* .react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
} */

.react-calendar__tile {
    max-width: 100%;
    padding: 1rem 0.8rem;
    background: none;
    text-align: center;
    line-height: 1rem;
    font: inherit;
    font-size: 1rem;
}

.react-calendar__month-view__days__day--neighboringMonth:disabled,
.react-calendar__decade-view__years__year--neighboringDecade:disabled,
.react-calendar__century-view__decades__decade--neighboringCentury:disabled {
    color: #cdcdcd;
}


/* .react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background-color: #e6e6e6;
} */


/* .react-calendar__tile--now {
    background: #1968c9;
} */

.present-date {
    background-color: #55DD33;
}

.absent-date {
    background-color: #FF0000;
    border-top-width: 1px;
}